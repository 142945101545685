import React, { useState, useMemo } from "react";
import styled from 'styled-components'
import QueryTable from "./QueryTable";
import WriteData from "../utils/WriteData";
import './Layout.css'


const TrainingTable = (props) => {


    const [dbWorkComplete, setDbWorkComplete] = useState(false)

    const [show, setShow] = useState(false);
    const REACT_APP_ENDPOINT_TRAINING=process.env.REACT_APP_ENDPOINT_TRAINING;
    const apiPath = REACT_APP_ENDPOINT_TRAINING + "/insert_video_click"
    // console.log("Training Table apiPath=", apiPath)

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }

          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: wrap;
            text-overflow: ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [

         {
            Header: "Module",
            accessor: "category",
            width: 200,
            Cell: ({row}) => {
                const showTrainingVideo = () => {
                  window.open(row.original.video_link, "_blank")
                  if (row.original.clicked_video !== "*") {
                    WriteData(
                      apiPath,
                      row.original,
                      "post",
                      window.sessionStorage.getItem('userId'),
                      props.refresh,
                      props.setRefresh,
                      setDbWorkComplete
                    )
                    row.original.clicked_video = "*"
                    setDbWorkComplete(false)
                  }
                }
                return (
                    <div onClick={()=> showTrainingVideo(row.original)} > {row.original.category} </div>
                )
                }
          },
          {
            Header: "Video Name",
            accessor: "Name",
            width: 280,
            Cell: ({row}) => {
                const showTrainingVideo = () => {
                  window.open(row.original.video_link, "_blank")
                  if (row.original.clicked_video !== "*") {
                    WriteData(
                      apiPath,
                      row.original,
                      "post",
                      window.sessionStorage.getItem('userId'),
                      props.refresh,
                      props.setRefresh,
                      setDbWorkComplete
                    )
                    row.original.clicked_video = "*"
                    setDbWorkComplete(false)
                  }
                }
            return (
                <div onClick={()=> showTrainingVideo()} > {row.original.name} </div>
            )
            }
          },
          {
            Header: "Description",
            width: 665,
            accessor: "description",
            style: { 'whiteSpace': 'unset' },
            Cell: ({row}) => {
                <div className="text-wrap"></div>
                const showTrainingVideo = () => {
                  window.open(row.original.video_link, "_blank")
                  if (row.original.clicked_video !== "*") {
                    WriteData(
                      apiPath,
                      row.original,
                      "post",
                      window.sessionStorage.getItem('userId'),
                      props.refresh,
                      props.setRefresh,
                      setDbWorkComplete
                    )
                    row.original.clicked_video = "*"
                    setDbWorkComplete(false)
                  }
                }
            return (
                <div onClick={()=> showTrainingVideo()} > {row.original.description} </div>
            )
            }
          },
          {
          Header: "*",
          width: 22,
          accessor: "clicked_video",
          style: { fontWeight: 'bolder'},
          Cell: ({row}) => {
              <div className="text-wrap"></div>
              const showTrainingVideo = () => {
                window.open(row.original.video_link, "_blank")
                if (row.original.clicked_video !== "*") {
                  WriteData(
                    apiPath,
                    row.original,
                    "post",
                    window.sessionStorage.getItem('userId'),
                    props.refresh,
                    props.setRefresh,
                    setDbWorkComplete
                  )
                  row.original.clicked_video = "*"
                  setDbWorkComplete(false)
                }
              }
          return (
              <div onClick={()=> showTrainingVideo()} > {row.original.clicked_video} </div>
          )
          }
        },
        ]
      },
    ],
   // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
      <Wrapper>
        <Styles>
            <QueryTable
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
            //   valueSelected={valueSelected}
            //   modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default TrainingTable;