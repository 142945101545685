// thanks to tannerlinsley / react-table / master /examples/virtualized-rows
import React from "react";
// import { useTable, useGlobalFilter, useBlockLayout } from 'react-table'
import { useTable, useGlobalFilter, useResizeColumns, useFlexLayout, } from 'react-table'
import { FixedSizeList } from 'react-window'
import scrollbarWidth from '../utils/scrollbarWidth'


const QueryTable = ({ columns, data, caller, show, setShow, valueSelected, modalType }) => {

  // console.log ("Table data= ", data)
  let placeholderValue= ""

  if (caller === "Utopia Experiences Training Videos") {
    placeholderValue = "You can search here and/or just click on the row to watch a video"
  }
  if (caller === "Utopia Experiences Training Assesments") {
    placeholderValue = "You can search here and/or just click on the row to take an assessment"
  }

  // Use the state and functions returned from useTable to build UI

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setGlobalFilter

  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: true,
    },
    // useBlockLayout,
    useResizeColumns,
    useFlexLayout,
    useGlobalFilter,

  )

  const handleFilterInputChange = (event) => {
    const { value } = event.currentTarget;
    setGlobalFilter(value);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,

          })}
          className="tr"
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )


  // Render the UI for your table
  return (
    <div>

    <input placeholder={placeholderValue}
        style={{height: "40px",
            width: "1168px",
            textAlign: "center",
            border: "1px solid #ccc",
            marginTop: "-600px",
            marginBottom: "10px"}}
        onChange={handleFilterInputChange} />
    <br/>

    <div {...getTableProps()} className="table">

      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={540}
          itemCount={rows.length}
          itemSize={60}
          width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
    </div>





  )
}



export default QueryTable