import React from "react";
import {Card, Button } from "react-bootstrap";
import './Sidebar.css'


// Fixed size sidbar menu for navigation

const Sidebar = (props) => {

    const REACT_APP_USER_GUIDE = process.env.REACT_APP_USER_GUIDE

    // console.log ("*****in Sidebar*****")
    // console.log ("refresh=", props.refresh)

    return (
        <>
        <Card >
        <div className="sidebar-menu-container">
        <br/><br/><br/>

            <Button className="menu-button"
                onClick={() => {
                   window.open(REACT_APP_USER_GUIDE, "_blank")
                    }
                }
            >User Guide</Button>
            <br/>

            <Button className="menu-button"
                onClick={() => {
                    props.setFormName("Utopia Experiences Training Videos")
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                        props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    }
                }
            >Training Videos</Button>
            <br/>

            <Button className="menu-button"
                onClick={() => {
                    props.setFormName("Utopia Experiences Training Assessments")
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                        props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    }
                }
            >Training Assessments</Button>
            <br/>


{/*
            <Button className="menu-button" href="/nowhere">This is some very long text to test with</Button>
            <br/>
            <Dropdown>
                <Dropdown.Toggle className="menu-button" id="dropdown-basic">
                    Dropdown Menu
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <br/> */}
            {/* <h4>Reserved for future use</h4> */}

        </div>
        </Card>
        </>
        )
  };
  export default Sidebar