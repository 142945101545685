import React from "react";
import './App.css';
import Authenticate from "./utils/Authenticate";
import Layout from './components/Layout';

function App() {

  // this application is only for registered UE Corp users.  Authenticate redirects if the user is not authenticated.
  Authenticate()

  return (
    <>
      <Layout/>
    </>
  );
}

export default App;
