import './Header.css';
import React  from "react"
import { Container, Navbar, Nav, Button, Stack} from 'react-bootstrap';
import logo from "../assets/UEFull_Whiteback.png"

const Header = () => {

  const REACT_APP_AUTHENTICATION_REDIRECT =process.env.REACT_APP_AUTHENTICATION_REDIRECT

    return (

     <Container fluid className="header-container" >
     <Navbar collapseOnSelect bg="white" expand="lg" fixed="top" >
            <Navbar.Brand  href="https://www.utopiaexperiences.net">
            <img className="logo"
                width='200px'
                height='auto'
                src={logo}
                alt="Utopia Experiences logo"
             />
            </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
            <Nav className="mr-auto">

              <Container fluid className="d-flex justify-content-center">

                  <Stack direction="horizontal" gap={2} >
                  <Button className="blue-button-header"
                    onClick={() => {
                      window.sessionStorage.clear()
                      }}
                    href={REACT_APP_AUTHENTICATION_REDIRECT}>
                    Care Community
                  </Button>
                  {/* <Button className="blue-button-header"
                    onClick={() => {
                      window.sessionStorage.clear()
                      window.localStorage.clear()
                      document.cookie = "accesstoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
                      }}
                      href={REACT_APP_AUTHENTICATION_REDIRECT}
                      >
                    Log Out
                  </Button> */}
                  </Stack>

              </Container>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      </Container>

    )
}

export default Header