import {toast } from 'react-toastify';
import axios from "axios";

const WriteData = (apiPath, data, method, keyValue, refresh, setRefresh, setDbWorkComplete) => {

    data['user_id'] = keyValue
    // console.log('WriteData apiPath= ', apiPath)
    // console.log('WriteData method= ', method)
    // console.log("WriteData keyValue= ", keyValue)
    // console.log('WriteData data=', data)

    let config = {
        headers: {
          'Authorization':  window.sessionStorage.getItem('idToken')
        },
      }

    let notify = () => toast("Record saved");

    function alertError (apiPath) {

        // alert thrown in the event of AWS api gateway or Server issues.

        alert("UE-Training WriteData:  There was an error saving to the database on this path: " + apiPath +". Please make a copy of this message, note what you were doing, and notify Support.")

        //when notitifying user of a fail, leave the spinner on.
        // setDbWorkComplete(false)
        return
    }

    if (method === "post") {

        // assign user's userId from session storage and default status for record
        // creation and add to data to pass to post

        // data['createdBy'] = window.sessionStorage.getItem('userId')
        // data['status'] = 1
        data['user_id'] = parseInt(keyValue)

        // console.log('WriteData post data=', data)

        // console.log('WriteData calling Axios post')
        axios.post(
            apiPath,
            data,
            config
        )
        .then((response)=>{
            // console.log ("axios return from post")
            // console.log ("refresh=", refresh)
            setDbWorkComplete(true)
            if (refresh  === true) {
                setRefresh(false)
            } else {
                setRefresh(true)
            }
            if (response.data.status === 'success'){
                // setDbWorkComplete (true)
                notify()
            }else if(response.data.status === 'fail') {
                console.log ("ERROR:  There was an error updating " + apiPath)
                //
                alertError (apiPath)
                setDbWorkComplete (false)
            }
            })
        .catch(err => {
            if (err.response) {
                 // client received an error response (5xx, 4xx)
                console.log ("Axios ERROR: ", err)
                // alertError (apiPath, setDbWorkComplete)
                alertError (apiPath)
            } else if (err.request) {
                // AWS api gateway error condition
                console.log ("ERROR:  There was an error connecting to the api gateway.")
                // alertError (apiPath, setDbWorkComplete)
                alertError (apiPath)
            } else {
                  // anything else
            }
        })

    }
    // else if (method === "put" || method === "delete") {

    //     // Note:  We don't actually delete records from the database.  For delete we set status = 5

    //     if (method === "delete") {
    //         data['status'] = 5
    //         console.log ('updated data.status = ', data['status'])
    //         notify = () => toast("Record marked as deleted");
    //     }
    //     console.log ("-----WriteData path=", apiPath)
    //     data['updatedBy'] = window.sessionStorage.getItem('userId')
    //     console.log ('updatedAt=', data.updatedAt)
    //     data['keyValue'] = keyValue
    //     console.log("keyValue=", keyValue)
    //     console.log('WriteData calling Axios put')
    //     console.log ("data.status = ", data.status)
    //     axios.put(
    //         apiPath,
    //         data,
    //         config
    //     )
    //     .then((response)=>{
    //         console.log ("axios return from put || delete")
    //         console.log ("axios return refresh =",  refresh)
    //         if (response.data.status === 'success'){
    //             notify()
    //             // setDbWorkComplete (true)  // this causes a memory leak, a react useEffect error.  Moving to callers for quick fix.  Requires research.
    //             if (!refresh) {
    //                 setRefresh(true)
    //             }
    //             else {
    //                 setRefresh(false)
    //             }
    //         }else if(response.data.status === 'fail') {
    //             console.log ("ERROR:  There was an error updating " + apiPath)
    //             alertError (apiPath, setDbWorkComplete)
    //         }
    //         })
    //     .catch(err => {
    //         if (err.response) {
    //              // client received an error response (5xx, 4xx)
    //             console.log ("Axios ERROR: ", err)
    //             alertError (apiPath, setDbWorkComplete)
    //             setDbWorkComplete (false)
    //         } else if (err.request) {
    //             // AWS api gateway error condition
    //             console.log ("ERROR:  There was an error connecting to the api gateway.")
    //             alertError (apiPath, setDbWorkComplete)
    //         } else {
    //               // anything else
    //         }
    //     })
    // }
    else {
        console.log ('WriteData ERROR:  Operation failed to execute' )
        // setDbWorkComplete(false)
    }
  return
}

export default WriteData