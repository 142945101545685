import React, {useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Container, Row, Col ,Card} from "react-bootstrap";
import Header from "./Header.jsx";
import Sidebar from "./Sidebar.jsx";
import QueryContainer from "./QueryContainer.jsx";
import './Layout.css'

// Lays out the Sidebar and form area.  Calls FormContainer which loads required
// data for the item selected by SideBar

const Layout = () => {

    //  Name the default blank screen, then callss FormConatain that renders forms based on Sidebar selections
    const [formName, setFormName] = useState('Utopia Experiences Client Training');

    //  Used throughout all forms and utilities to cause database refresh
    const [refresh, setRefresh] = useState(false)


    return (
        <>
        <Header/>
        <Container fluid >
            <Row className='d-flex justify-content-center'>
                <Col md={2} className="sidebar-wrapper">
                    <Sidebar
                    setFormName={setFormName}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    />
                </Col>
                <Col md={8}  >
                    <br/>
                    {(formName === "Utopia Experiences Client Training") &&
                    <div className = "banner ">
                        <h1 >Utopia Experiences Community Care Training</h1>
                    </div>
                    }
                    {(formName !== "Utopia Experiences Client Training") &&
                    <Card  className = "card-style" >
                        <Card.Title className="form-title"
                            ><h1>{formName}</h1>
                        </Card.Title>
                        <Card.Body className = "card-body-style"  >
                            <QueryContainer
                                refresh={refresh}
                                setRefresh={setRefresh}
                                formName={formName}
                                />

                        </Card.Body>
                    </Card>
                    }
                </Col>
            </Row>

            </Container>
        </>
        );
  };

  export default Layout