import React, { useState,} from "react";
import {Container, Spinner} from "react-bootstrap";
import GetData from "../utils/GetData"
import AssessmentTable from "./AssessmentTable";
import TrainingTable from "./TrainingTable";
import "./Form.css"

// Runs queries against the database.  Calls the SelectBar, and ModalContainer for popup to display
// CRUD forms, which do the data entry work.  Also displays a table of query results for selection
// which will also take the user to ModalContainer and CRUD forms.

const QueryContainer = (props) => {

  let requestPath = ''
  let caller = ''

  const REACT_APP_ENDPOINT_TRAINING=process.env.REACT_APP_ENDPOINT_TRAINING;

  const [getDataComplete, setGetDataComplete] = useState(false);

  // function getTimezoneOffset() {
  //   function z(n){return (n<10? '0' : '') + n}
  //   var offset = new Date().getTimezoneOffset();
  //   var sign = offset < 0? '+' : '+';  // flip for mysql
  //   offset = Math.abs(offset);
  //   return sign + z(offset/60 | 0) + ":" + z(offset%60);
  // }

  // let timezoneOffset = getTimezoneOffset()
  // console.log ("*************** UTC Offset=", timezoneOffset)

  if (props.formName === "Utopia Experiences Training Videos") {
    requestPath = REACT_APP_ENDPOINT_TRAINING + "/training_videos"
  }
  if (props.formName === "Utopia Experiences Training Assessments") {
    requestPath = REACT_APP_ENDPOINT_TRAINING + "/assessments"
  }

  caller = props.formName

  let refresh = props.refresh

  // console.log("QueryContainer refresh=", props.refresh)


  const formData = GetData(requestPath, refresh, setGetDataComplete, window.sessionStorage.getItem('userId'))
  // console.log ("QueryContainer formData =", formData)


  return (

    <Container fluid >

    <div className="d-flex align-items-center justify-content-center button-box">

    </div>

    {(!getDataComplete) &&
        <div >
          <Spinner animation="grow" size="sm" role="status" style={{color: "#00447c"}}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }

    {(props.formName === "Utopia Experiences Training Videos") &&
        <TrainingTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
        />
    }

    {(props.formName === "Utopia Experiences Training Assessments") &&
        <AssessmentTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
        />
    }

    </Container>
  );
}

export default QueryContainer